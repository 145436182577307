<template>
  <div class="bg_white">

    <div class="flow_t">{{ record.business ? record.business.name : '-' }}</div>

    <div class="flow_m" v-html="getSymbol(record)"></div>

    <div class="flow_b">
      <ul>
        <li class="clear">
          <span>消费类型</span>
          <label>{{ getTransactionDesc(record) }}</label>
        </li>
        <li class="clear" v-if="record.type=='DEDUCTION'">
          <span>商品名称</span>
          <label>{{ record.productBundle ? record.productBundle.name : '自由消费' }}</label>
        </li>
        <!--<li class="clear">-->
        <!--<span>商户名称</span>-->
        <!--<label>广发行收款账户全称</label>-->
        <!--</li>-->
        <li class="clear" v-if="record.purchase">
          <span>用餐时间</span>
          <label>{{ record.purchase.consumeTime | dayjs }}</label>
        </li>
        <li class="clear" v-if="record.purchase">
          <span>扣费时间</span>
          <label>{{ record.createdTime | dayjs }}</label>
        </li>
        <li class="clear" v-if="record.type==='RECHARGE'">
          <span>操作时间</span>
          <label>{{ record.createdTime | dayjs }}</label>
        </li>
        <li class="clear" v-if="record.purchase">
          <span>交易单号</span>
          <label>{{ record.purchase.code }}</label>
        </li>
      </ul>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>


<style scoped>
</style>

<script>
import {post} from '@/utils/http'

export default {
  data() {
    return {
      id: null,
      record: {},
      loading: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.loading = true
    post('api/transaction/log/v2/search/owner', {
      filters: [{name: 'id', op: '=', value: this.id}],
      sort: 'createdTime desc',
      page: 0,
      size: 10
    }).then(res => {
      this.record = res.data.rows[0]
      this.loading = false
    }).catch(() => this.loading = false)
  },
  methods: {
    // 时间差计算
    difference(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      return dayDiff * 24 * 60 + hours * 60 + minutes;
    },
    getTransactionDesc(data) {
      var type = data.type;
      // 显示补扣费逻辑，流水的创建时间-消费时间>60分钟
      if (type === 'RECHARGE') {
        return '充值';// 充值
      }
      if (type === 'DEDUCTION') {
        if (data.remark === '储值卡消费') return '红包';
        if (data.remark === '预扣费') return '预扣费';
        if (data.purchase) {
          if (this.difference(data.purchase.consumeTime, data.createdTime) > 60) {
            console.log(data.purchase.consumeTime, data.createdTime, this.difference(data.purchase.consumeTime, data.createdTime))
            return '补扣费';
          }
        }
        return '扣费';// 消费 补扣费 红包
      }
      if (type === 'WITHDRAW') {
        return '提现';// 提现
      }
      if (type === 'REFUND') {
        return '退款';// 退费
      }
      if (type === 'PARTIAL_REFUND') {
        return '部分退款';// 退费
      }
      return type;
    },
    getSymbol(data) {
      if (data.type === 'RECHARGE') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'DEDUCTION') return '<div style="font-weight: bold;" >-' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'WITHDRAW') return '<div style="font-weight: bold;" >-' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'REFUND') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'PARTIAL_REFUND') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      return '';
    }
  }
};
</script>
